$(function(){
	$(function(){
    
    /* pagetop*/
    var topBtn = $('#pagetop');
    topBtn.hide();
    $(window).scroll(function(){
      if ($(this).scrollTop() >100) {
        topBtn.fadeIn();
      } else {
        topBtn.fadeOut();
      }
    });
    topBtn.click(function () {
      $('body,html').animate({scrollTop:0},500);
      return false;
    });
    
    //----------------------------------------//
    //MENU
    //----------------------------------------//
    var sflag = true;
    $('.menu_btn a').on('click',function(){
      MenuClose(sflag);
      return false;
    });

    function MenuClose(_mflg){
      if(_mflg){
        $('.menu-trigger').addClass("active");
        $('.header_menu').addClass('menuOpen');
        sflag = false;
      }else{
        $('.menu-trigger').removeClass("active");
        $('.header_menu').removeClass('menuOpen');
        sflag = true;
      }
    }
    
    //----------------------------------------//
    //scrollTop
    //----------------------------------------//
     $('.header_menu a[href^="#"], #pagetop a[href^="#"]').click(function(){
        var speed = 500;
        var href= $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        var position = target.offset().top;
        $('body,html').animate({scrollTop:position}, speed, 'swing');
        return false;
     });
    
  });
});
